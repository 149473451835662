<!--
 * @Author: huadan
 * @Date: 2021-04-21 13:22:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-20 17:23:48
 * @Description: 可编辑单元格
-->
<style lang="less" scoped>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  width: 70px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  top: calc(50% - 10px);
  width: 10px;
  cursor: pointer;
}

.editable-cell-icon {
  display: none;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}
</style>

<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-input :value="value" @change="handleChange" @pressEnter="check" /><a-icon
        type="check"
        class="editable-cell-icon-check"
        @click="check"
      />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      <span class="warning">{{ value || ' ' }}</span>
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditableCell',
  props: {
    text: String,
  },

  data() {
    return {
      value: this.text,
      editable: false,
    }
  },

  methods: {
    handleChange(e) {
      const value = e.target.value
      this.value = value
    },
    check() {
      this.editable = false
      this.$emit('change', this.value)
    },
    edit() {
      this.editable = true
    },
  },
}
</script>
